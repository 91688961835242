<template>
    <div class="zt-page-content">
        <div v-if="showImgs" style="overflow-y:auto;">
            <!-- 上传自有商品 -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="120px">
                <div class="zt-block">
                    <div class="zt-block-head">基本信息</div>
                    <div class="zt-block-content">
                        <el-form-item label="商品名称" prop="name">
                            <el-input v-model="ruleForm.name" maxlength="30" placeholder="填写商品名称"></el-input>
                            <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                                >{{(ruleForm.name || '').length}}/30</span>
                        </el-form-item>
                        <el-form-item label="商品简称">
                            <el-input v-model="ruleForm.sname" maxlength="30" placeholder="填写商品简称"></el-input>
                            <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                                >{{(ruleForm.sname || '').length}}/30</span>
                        </el-form-item>
                        <el-form-item label="商品型号" prop="module_no">
                            <el-input v-model="ruleForm.module_no" placeholder="填写商品型号"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类名称">
                            <div class="flex">
                                <div style="margin-right:32px;">
                                    <!-- <el-select v-model="ruleForm.set_no"  placeholder="请选择商品分类">
                                        <el-option v-for="item in series" :key="item.set_no" :label="item.name" :value="item.set_no">
                                        </el-option>
                                        <el-option :label="'未分类'" :value="''">未分类</el-option>
                                    </el-select> -->
                                    <el-cascader :options="classify" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                        v-model="ruleForm.custom_cat_no" placeholder="请选择商品分类">
                                    </el-cascader>
                                </div>
                                <div>
                                    <router-link :to="{ path:'/factory/txgl' }" target="_blank">
                                        <el-button type="primary" plain>新建商品分类</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="单品风格" prop="style_no">
                            <el-select v-model="ruleForm.style_no" placeholder="请选择风格">
                                <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品描述">
                            <el-input v-model="ruleForm.intro" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="单品类目" prop="cat_id">
                            <el-cascader :options="cats" :disabled="product_no&&catId?true:false" @change="handleCat" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                v-model="ruleForm.cat_id" placeholder="请选择类目">
                            </el-cascader>
                        </el-form-item>
                        <div v-if="ruleForm.attr && ruleForm.attr.length>0 && ruleForm.attr[0].id">
                            <el-form-item class="block-bg" :style="(idx+1)==ruleForm.attr.length ? 'margin-bottom:0;' : ''"
                                v-for="(attr,idx) in ruleForm.attr" :key=idx 
                                :prop="attr.nullable ? ('attr.' + idx + '.values') : ''"
                                :rules="attr.nullable ? rules.attr : []"
                                :label="attr.name">
                                <div v-if="attr.type=='single' && attr.items && attr.items.length>0">
                                    <el-select v-model="attr.values" placeholder="请选择">
                                        <el-option v-for="item in attr.items" :key="item.id" :label="item.value" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                                <div v-if="attr.type=='mutil' && attr.items && attr.items.length>0">
                                    <el-checkbox-group v-model="attr.values">
                                        <el-checkbox v-for="item in attr.items" :key="item.id" :label="item.id" :value="item.id">{{item.value}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">设计信息</div>
                    <div class="zt-block-content">
                        <el-form-item label="设计尺寸">
                            <DesignSize v-model="ruleForm.dimension"></DesignSize>
                        </el-form-item>
                        <el-form-item style="padding-top:10px;" label="设计理念">
                            <div style="position:relative;max-width:500px;">
                                <el-input type="textarea" v-model="ruleForm.design_idea" maxlength="120" rows="5" style="max-width:500px;"></el-input>
                                <div class="font14-grey1" style="position:absolute;right:10px;bottom:6px;">{{(ruleForm.design_idea || '').length}}/120</div>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">图片与视频</div>
                    <div class="zt-block-content">
                        <el-form-item label="3D 单品渲染图" prop="photo_render">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_render" prefix='product/render'></UploadImgss>
                            </div>
                        </el-form-item>
                        <el-form-item label="单品视频" prop="video" style="margin-bottom:10px;">
                            <div style="position:relative;">
                                <UploadVideo v-if="showImgs" v-model="ruleForm.video" prefix='product/video'></UploadVideo>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="this.ruleForm.video && this.ruleForm.video.length>0" label="视频封面图" prop="video_thumb" style="margin-bottom:10px;">
                            <div>
                                <UploadImg v-if="showImgs" v-model="ruleForm.video_thumb" prefix='product/video/cover'></UploadImg>
                            </div>
                        </el-form-item>
                        <el-form-item label="标高图">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                            </div>
                        </el-form-item>
                        <el-form-item label="素材图" style="margin-bottom:0px;">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">文件</div>
                    <div class="zt-block-content">
                        <el-form-item label="设计CAD文件：" style="margin-bottom:0;">
                            <div style="position:relative;">
                                <UploadCads v-if="showImgs" v-model="ruleForm.photo_cad" prefix='product/cad'></UploadCads>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head" v-if="ruleForm.accessory && ruleForm.accessory.length>0">附件信息</div>
                    <div class="zt-block-content" v-if="ruleForm.accessory && ruleForm.accessory.length>0" style="padding:0 30px;">
                        <div class="list-item" v-for="(item,idx) in ruleForm.accessory" :key="idx">
                            <div style="position:absolute;top:30px;right:0;z-index:999;" class="flex flex-align-center">
                                <div style="font-size:16px;color:#292929;line-height:42px;margin-right:10px">附件{{idx+1}} </div>
                                <div v-if="item.id">
                                    <el-button v-if="item.stat==3" @click="changeSwitch(item,idx)" size="mini" type="primary" plain>启用</el-button>
                                    <el-button v-if="item.stat==2" @click="changeSwitch(item,idx)" size="mini" type="primary" plain>禁用</el-button>
                                </div>
                                <el-button v-else @click="delAccessory(idx)" style="font-size:14px;color:#FF4D4F;" type="text">
                                    <i class="iconfont icon-icon7"></i> 删除
                                </el-button>
                            </div>
                            <el-form-item label="附件名称" :prop="'accessory.'+ idx +'.name'" :rules="rules.accessoryName">
                                <el-input v-model="item.name" placeholder="请填写附件名称"></el-input>
                            </el-form-item>
                            <el-form-item label="型号" :prop="'accessory.'+ idx +'.module_no'" :rules="rules.accessoryModuleNo">
                                <el-input v-model="item.module_no" placeholder="请填写型号"></el-input>
                            </el-form-item>
                            <el-form-item label="设计尺寸">
                                <DesignSize v-model="item.dimension"></DesignSize>
                            </el-form-item>
                            <el-form-item label="标高图">
                                <div>
                                    <UploadImgss v-if="showImgs" v-model="item.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                                </div>
                            </el-form-item>
                            <el-form-item label="素材图" style="margin-bottom:0px;">
                                <div>
                                    <UploadImgss v-if="showImgs" v-model="item.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="add-accessory flex flex-align-center flex-pack-center">
                        <div @click="addAccessory()" class="addSeries">
                            <span>+</span>
                            <span>点击添加附件</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="zt-block add-accessory flex flex-align-center flex-pack-center">
                    <div @click="addAccessory()" class="addSeries">
                        <span>+</span>
                        <span>点击添加附件</span>
                    </div>
                </div> -->
                <div class="zt-block btn-block">
                    <div style="max-width:400px;margin:0 auto;">
                        <div v-if="product_no" class="flex flex-justify-around">
                            <!-- <el-button @click="preview()" size="small" type="text">
                                <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                <span>上架预览</span>
                            </el-button> -->
                            <el-button size="small" type="primary" @click="back()">取消</el-button>
                            <el-button size="small" type="primary" @click="editSubmit()">保存</el-button>
                        </div>
                        <div v-else class="flex flex-justify-around">
                            <!-- <el-button @click="preview()" size="small" type="text">
                                <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                <span>上架预览</span>
                            </el-button> -->
                            <el-button size="small" @click="saveDraft()" type="primary" plain>存草稿</el-button>
                            <el-button size="small" @click="submitShow()" type="primary">保存</el-button>
                        </div>
                    </div>
                </div>
            </el-form>

        </div>

        <!-- 选择商品分类弹窗 -->
        <el-dialog width="540px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <!-- <SelectTxgl v-if="popTitle=='自定义商品商品分类'" :type="price_type" 
                @hide="hide"></SelectTxgl> -->
            <div v-if="popTitle=='温馨提示'">
                <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
                    <p style="font-size:16px;color:#292929;">商品发布确认</p>
                    <p style="font-size:14px;color:#808080;">商品发布后，会在集市和销售手册中展示，请仔细核对商品详情</p>
                </div>
                <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
                    <el-button style="margin-right:20px;" @click="submitHide()" size="small" type="primary" plain>再修改一下</el-button>
                    <el-button size="small" type="primary" @click="zyspSubmit()">确认并发布</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import store from '@/store';
    import { getCats, getStyles, classifyList, getAttrs } from '@/service/item';
    import { uploadFile } from '@/service/common';
    import { getSelfId,addSelf,setSelfProduct,setStepPrice } from '@/service/factory';
    import { fetchTree } from '@/util';
    // import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UploadImgss from '@/components/upload/UploadImgss.vue';
    import UploadVideo from '@/components/upload/UploadVideo.vue';
    import UploadImg from '@/components/upload/UploadImg.vue';
    import UploadCads from '@/components/upload/UploadCads.vue';
    // import SelectTxgl from '@/views/factory/txgl/SelectTxgl.vue';
    import DesignSize from '@/components/factory/DesignSize.vue';

    export default {
        components:{
            // UploadImgs,
            UploadImgss,
            UploadVideo,
            UploadImg,
            UploadCads,
            // SelectTxgl,
            DesignSize
        },
        props: {},
        computed: {},
        data() {
            let checkVideo = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请上传视频封面图'));
                } else {
                    callback();
                }
            };
            return {
                imgUrl: IMG_URL_PRE,
                popTitle:null,
                popVisible:false,
                ruleForm: {
                    name: null,
                    sname: null,
                    module_no:null,
                    intro:null,
                    custom_cat_no:null,
                    style_no: null,
                    cat_id: null,
                    design_idea:null,
                    price:null,
                    // size: { len: null,wlen: null,height: null, },
                    dimension: [],
                    photo_render:[],
                    video:[],
                    video_thumb:null,
                    photo_cad:[],
                    photo_size:[],
                    photo_story:[],
                    accessory:[],
                    attr: [],
                    // publish:true,
                },
                rules: {
                    name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
                    // sname: [{ required: true, message: '请填写商品简称', trigger: 'blur' }],
                    module_no: [{ required: true, message: '请填写商品型号', trigger: 'blur' }],
                    // set_no: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
                    style_no: [{ required: true, message: '请选择风格', trigger: 'blur' }],
                    cat_id: [{ required: true, message: '请选择类目', trigger: 'blur' }],
                    attr: [{
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else if (value && JSON.stringify(value) != '[]') {
                                callback();
                            } else {
                                let _idx = rule.field.split(".")[1];
                                let _name = this.ruleForm.attr[_idx].name;
                                if (!value) {
                                    callback(new Error(`请选择${_name}`));
                                } else {
                                    callback(new Error(`${_name} 至少选择一项!`));
                                }
                            }
                        },
                        trigger: "blur"
                    }],
                    design_idea: [{ required: true, message: '请填写设计理念', trigger: 'blur' }],
                    photo_render: [{ required: true, message: '请上传3D 单品渲染图', trigger: 'blur' }],
                    video_thumb: [{ required: true, validator: checkVideo, trigger: 'blur' }],
                    price: [{ required: true, message: '请填写价格', trigger: 'blur' }],
                    accessoryName: [{ required: true, message: '请填写附件名称', trigger: 'blur' }],
                    accessoryModuleNo: [{ required: true, message: '请填写型号', trigger: 'blur' }],
                },
                autoSave: null,
                catList:[],
                cats: [],
                styles: [],
                classify:[],
                product_no:null,
                catId:null,
                showImgs:false,
                timeVisible:false,
                quoteInfo:null,
                checkedCities:[],
            }
        },
        watch: {
            'ruleForm.video': {
                handler(newVal, oldVal) {
                    // 删除视频同时删除图片
                    if (newVal && newVal.length==0) {
                        this.ruleForm.video_thumb=null;
                    }
                },
                deep: true
            }
        },
        async created() {
            this.product_no = this.$route.query.id;
            if (this.$route.query.set_no) {
                this.ruleForm.custom_cat_no=this.$route.query.set_no;
            }
            this.catList = await getCats();
            this.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.styles = await getStyles();
            let classify = await classifyList(this.$store.state.loginUser.comp.id);
            classify = fetchTree(classify, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            classify.forEach(e => {
                if (e.children && e.children.length>0) {
                    this.classify.push(e);
                }
            });
        },
        mounted() {
            if (this.product_no) {
                let _this = this;
                getSelfId(this.product_no).then(rst => {
                    console.log(rst);
                    this.catId = rst.cat.id;
                    if (rst) {
                        this.ruleForm = {
                            name: rst.name,
                            sname: rst.sname,
                            module_no:rst.module_no,
                            intro:rst.intro,
                            custom_cat_no:rst.custom_cat_no,
                            style_no: rst.style.id,
                            cat_id: rst.cat.id,
                            design_idea:rst.design_idea,
                            price:rst.price,
                            // size: rst.size,
                            dimension:rst.dimension,
                            photo_render:rst.photo_render,
                            video:rst.video,
                            video_thumb:rst.video_thumb,
                            photo_cad:rst.photo_cad,
                            photo_size:rst.photo_size,
                            photo_story:rst.photo_story,
                            accessory:rst.accessory,
                            attr:rst.attr,
                        };
                        this.get_attrs(this.catId);
                        this.showImgs = true;
                    }
                    console.log(this.ruleForm);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            } else {
                this.showImgs = true;  
            }
        },
        destroyed() {
            if (this.autoSave) clearInterval(this.autoSave);
        },
        methods: {
            // 选择类目
            handleCat(e) {
                console.log(e);
                this.get_attrs(e);
            },
            // 获取类目下面的spu属性
            get_attrs(catId) {
                getAttrs(catId,{hasValue:true}).then(rst => {
                    rst.forEach(e => {
                        this.$set(e,'values',[]);
                        if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                            this.ruleForm.attr.forEach((n)=>{
                                if (e.id == n.name) {
                                    if (e.type=='mutil') {
                                        e.values = n.values;
                                    } else e.values = n.values[0];
                                }
                            })
                        }
                    });
                    this.ruleForm.attr = rst;
                    console.log(this.ruleForm.attr);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            back() {
                this.$router.go(-1);
            },
            addAccessory() {
                this.ruleForm.accessory.push({name: '',module_no:'',dimension: [],photo_size: [],photo_story:[]})
            },
            delAccessory(index) {
                this.$confirm('确认是否删除附件?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.accessory.splice(index,1);
                });
            },
            changeSwitch(item,index) {// 附件的启用禁用开关
                let text=null,stat=null;
                if (item.stat==2) {
                    text = "禁用"; stat = 3;
                } else if (item.stat==3) {
                    text = "启用"; stat = 2;
                }
                this.$confirm('确认是否'+text+'?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.ruleForm.accessory[index].stat=stat;
                });
                console.log(item);
            },
            checkRuleForm() {
                let attr = [];
                if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                    this.ruleForm.attr.forEach((e)=>{
                        if (e.type == "single") {
                            attr.push({
                                name: e.id,
                                values: [e.values]
                            })
                        } else if (e.type == "mutil") {
                            attr.push({
                                name: e.id,
                                values: e.values
                            })
                        }
                    })
                }
                return attr;
            },
            // 存草稿
            saveDraft() {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                if (!_item.sname) {
                    _item.sname = _item.name;
                }
                // _item.publish = 0;
                addSelf(_item).then(rst => {
                    _loading.close();
                    this.$message.success('草稿保存成功');
                    this.$router.go(-1);
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
            // 编辑保存
            editSubmit() {
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                _item.publish = 1;
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        if (this.product_no) {
                            _item.product_no = this.product_no;
                            setSelfProduct(_item).then(rst => {
                                this.$message.success('保存成功');
                                this.$router.go(-1);
                            }).catch(err => {
                                this.$message.error(err.message);
                            });
                        }
                    }
                });
            },
            submitShow() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        // this.popTitle = "温馨提示";
                        // this.popVisible = true;
                        this.zyspSubmit();
                    }
                });
            },
            submitHide() {
                this.popVisible = false;
            },
            zyspSubmit(){
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                if (!_item.sname) {
                    _item.sname = _item.name;
                }
                console.log(_item);
                addSelf(_item).then(rst => {
                    _loading.close();
                    this.$message.success('保存成功');
                    this.submitHide();
                    this.$router.go(-1);
                    this.product_no = rst.product_no;
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped lang="less">
    .el-steps {
        width: 700px;
        padding: 10px 51px;
    }
    .list-item {
        position:relative;
        padding:30px 0;
        border-bottom:1px solid #E6E6E6;
    }
    .list-item:last-child {
        border-bottom:0;
    }
    .add-accessory {
        height: 70px;
        border-top: 1px solid #E6E6E6;
        >span {
            font-size: 16px;
        }
        .addSeries {
            text-align: center;
            // padding: 10px 90px 0;
            cursor: pointer;
            >span {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #016FFF;
                &:first-child {
                    width: 18px;
                    height: 18px;
                    line-height: 15px;
                    text-align: center;
                    font-size: 18px;
                    color: #016FFF;
                    border: 1px solid #016FFF;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    // .selected {
    //     margin-left: 53px;
    //     color: #808080;
    //     >div {
    //         margin: 0 0 8px 72px ;
    //         &:first-child {
    //             margin-left: 0;
    //         }
    //     }
    // }
    .btn-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        .el-button {
            margin-left: 48px;
        }
        a {
            margin-left: 48px;
            margin-right: 10px;
        }
    }
    .add-btn {
        height: 80px;
        color: #016FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 46px;
        cursor: pointer;
        i {
            font-size: 18px;
            margin-right: 16px;
        }
    }
    .el-cascader,.el-input, .el-select {
        width: 240px;
    }
    .el-dialog {
        .el-dialog__body {
            >div {
                color: #808080;
                label {
                    margin-right: 23px;
                }
                &:last-child {
                    padding-bottom: 32px;
                    border-bottom: 1px solid #E6E6E6;
                    label {
                        display: block;
                    }
                }
            }
        }
    }
</style>